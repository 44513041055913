import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/HomePage'
import VenuePage from './components/VenuePage'
import ProgramPage from './components/ProgramPage'
import OurStoryPage from './components/OurStoryPage'
import PhotosPage from './components/PhotosPage'
import RsvpPage from './components/RsvpPage'
import RsvpList from './components/RsvpList'
import SelfiePage from './components/SelfiePage'


export default new createRouter({
    history: createWebHistory(),
    routes: [
      { path: '/', component: HomePage },
      { path: '/venue', component: VenuePage },
      { path: '/programme', component: ProgramPage },
      { path: '/our-story', component: OurStoryPage },
      { path: '/photos', component: PhotosPage },
      { path: '/RSVP', component: RsvpPage },
      { path: '/rsvp-list', component: RsvpList },
      { path: '/selfie-challenge', component: SelfiePage }
    ]
})