<template>
    <div class="flex flex-col justify-center content-center">
        <img src="/assets/background.JPG" class="w-full shadow-lg rounded-lg" alt="Paul & Rachelle prenup photo">

        <div class="flex flex-wrap mt-12 shadow-sm lg:shadow-none xl:shadow-none">
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-end xl:justify-end">
            <div class="text-center lg:text-end xl:text-end p-6 my-auto text-4xl text-primary font-croissant">
              With family & friends, <br /> we invite you to <br/> celebrate our wedding day.</div>
          </div>
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-start xl:justify-start border-primary lg:border-l-2 xl:border-l-2">
            <div class="w-36 h-36">
              <img src="/assets/svgs/undraw_new_message_re_fp03.svg" alt="Invitation"/>
            </div>
          </div>
        </div>

        <div class="flex flex-col-reverse lg:flex-row xl:flex-row mt-12 shadow-sm lg:shadow-none xl:shadow-none">
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-end xl:justify-end border-primary lg:border-r-2 xl:border-r-2">
            <div class="w-36 h-36">
              <img src="/assets/svgs/undraw_time_management_re_tk5w.svg" alt="Schedule"/>
            </div>
          </div>
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-start xl:justify-start">
            <div class="text-center lg:text-start xl:text-start p-6 my-auto text-4xl text-primary font-croissant">January 25, 2024, <br /> 1 pm Thursday </div>
          </div>
        </div>

        <div class="flex flex-wrap mt-12 shadow-sm lg:shadow-none xl:shadow-none">
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-end xl:justify-end">
            <div class="text-center lg:text-end xl:text-end p-6 my-auto text-4xl text-primary font-croissant">Diocesan Shrine <br /> Parish of the Our Lady of the Pillar,<br /> Imus Cathedral</div>
          </div>
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-start xl:justify-start border-primary lg:border-l-2 xl:border-l-2">
            <div class="w-36 h-36">
              <img src="/assets/svgs/undraw_wedding_re_66hj.svg" alt="Wedding Ceremony"/>
            </div>
          </div>
        </div>

        <div class="flex flex-col-reverse lg:flex-row xl:flex-row mt-12 shadow-sm lg:shadow-none xl:shadow-none">
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-end xl:justify-end border-primary lg:border-r-2 xl:border-r-2">
            <div class="w-36 h-36">
              <img src="/assets/svgs/undraw_awards_fieb.svg" alt="Wedding Reception"/>
            </div>
          </div>
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-start xl:justify-start">
            <div class="text-center lg:text-start xl:text-start p-6 my-auto text-4xl text-primary font-croissant">Reception @ Patio Luisa <br/> Toclong II Street, Imus, Cavite</div>
          </div>
        </div>

        <div class="flex flex-wrap mt-12 shadow-sm lg:shadow-none xl:shadow-none">
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex flex-col justify-center lg:justify-end xl:justify-end">
            <div class="text-center lg:text-end xl:text-end p-6 my-auto text-4xl text-primary font-croissant">
              To reserve, <br /> Please fill up to this form <router-link to="/RSVP" class="router-link bg-primary">RSVP</router-link>
            </div>
            
          </div>
          <div class="w-full lg:w-1/2 xl:w-1/2 p-0 lg:p-12 xl:p-12 flex justify-center lg:justify-start xl:justify-start border-primary lg:border-l-2 xl:border-l-2">
            <div class="w-36 h-36">
              <img src="/assets/svgs/undraw_booking_re_gw4j.svg" alt="Wedding Reception"/>
            </div>
          </div>
        </div>

    </div>
</template>

<script>

export default {
  name: 'HomePage'
}
</script>