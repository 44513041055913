<template>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
            <div class="w-64 h-auto p-6 mx-auto">
                <img src="/assets/svgs/undraw_wedding_re_66hj.svg" alt="Wedding Ceremony"/>
            </div>
            <div class="flex flex-col text-gray-700 p-6 w-full">
                <div class="w-auto my-auto flex">
                    <h3 class="text-4xl text-primary">Ceremony - Thursday, January 25, 2024</h3>
                </div>
                <div class="w-full my-auto font-sans">
                    <h5 class="text-xl mt-4 underline"><a href="https://maps.app.goo.gl/ZmDdUN1zKMZ2WZkw8" target="_blank">Diocesan Shrine - Parish of the Our Lady of the Pillar (Imus Cathedral)</a></h5>
                    <div class="mt-2 text-sm">Address: General Castañeda St, Imus, Cavite</div>
                    <div class="text-sm">Time: 1PM - 3PM</div>
                </div>
            </div>
        </div>
        <div class="w-full border-t border-gray-200">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.9582320883064!2d120.93352577627202!3d14.429570681238705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d2f49e2d502d%3A0xd32bcd932914f10f!2sDiocesan%20Shrine%20and%20Parish%20of%20Our%20Lady%20of%20the%20Pillar%20(Imus%20Cathedral)!5e0!3m2!1sen!2sph!4v1703247325700!5m2!1sen!2sph" width="100%" height="450" allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    <div class="font-croissant rounded-lg shadow-lg mt-12 text-center lg:text-start xl:text-start border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
            <div class="w-64 h-auto p-6 mx-auto">
                <img src="/assets/svgs/undraw_awards_fieb.svg" alt="Wedding Reception"/>
            </div>
            <div class="flex flex-col text-gray-700 p-6 w-full">
                <div class="w-auto my-auto flex">
                    <h3 class="text-4xl text-primary">Reception - Thursday, January 25, 2024</h3>
                </div>
                <div class="w-full my-auto font-sans">
                    <h5 class="text-xl mt-4 underline"><a href="https://maps.app.goo.gl/NF28wP8xDa9PuF7z5" target="_blank">Patio Luisa</a></h5>
                    <div class="mt-2 text-sm">Address: 60 Toclong II St. Imus, Cavite</div>
                    <div class="text-sm">Time: 4PM - 9PM</div>
                </div>
            </div>
            
        </div>
        <div class="w-full border-t border-gray-200">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.7085093038154!2d120.92411277627211!3d14.443954880890244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d29a67fc27e1%3A0x3a2013754e5f4866!2sPatio%20Luisa!5e0!3m2!1sen!2sph!4v1703247945615!5m2!1sen!2sph" width="100%" height="450" allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    
    
</template>