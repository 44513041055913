<template>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center p-4 lg:px-24 xl:px-24 xl:py-12 lg:py-12 lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
            <div class="w-full my-auto">
                <h3 class="text-4xl text-primary">How we met</h3>
                <div class="mt-2 text-justify font-sans">
                    Who would have thought that someone would find his true love in an unexpected way. Back in 2022's semestral break, 
                    our story started on Facebook Dating. After our profile matched, we decided to get to know each other. Since then, 
                    we haven’t stopped chatting and talking about ourselves. When the time came to meet her in person, I have decided to pursue her. 
                    After a month of courtship, I was happy when she agreed to go to a long-term relationship. Even though we didn't have any mutual 
                    friends at first, our relationship bloomed. Later, we were surprised when we found out we're connected—my sister's best friend is 
                    the sister of Rachelle's best friend. <br /><br />
                    Last year - 2023, we worked together to accomplish things and to overcome challenges. We also filled it with different adventures 
                    and journey together. We explored various places, from the North loop to Cebu and Bohol for short period of time. I always love to 
                    travel with her to share the beauty of the different places and create memories with her. Here's our first photo and convo together:
                </div>
                <div class="flex space-y-2 flex-col justify-around mt-6 lg:space-x-2 xl:space-x-2 lg:h-96 xl:h-96 lg:flex-row xl:flex-row">
                    <img src="/assets/ourstory/ourstory.jpg" class="w-auto h-full shadow-lg" alt="Our first photo together"/>
                </div>
            </div>
        </div>
    </div>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start border mt-12 border-gray-200">
        <div class="flex flex-wrap flex-col justify-center p-4 lg:px-24 xl:px-24 xl:py-12 lg:py-12 lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
            <div class="w-full my-auto">
                <h3 class="text-4xl mt-4 text-primary">The proposal</h3>
                <div class="mt-2 text-justify font-sans">
                    I have been dreaming and waiting to find a girl like her so I did not waste my time and I decided to propose to her.
                    It was the 3rd quarter of the year 2023, I ask for the consent and help of Rachelle's family and with their help,
                    Rachelle finally said yes!
                </div>
                <div class="flex flex-wrap gap-2 lg:space-y-0 xl:space-y-0 flex-col justify-center mt-6 lg:h-auto xl:h-auto lg:flex-row xl:flex-row">
                    <img src="/assets/ourstory/proposal.jpg" class="w-full h-auto lg:w-auto lg:h-72 xl:w-auto xl:h-72 shadow-lg" alt="The proposal"/>
                </div>
            </div>
        </div>
    </div>
</template>