<template>
    <div class="text-center mt-4">
        <h1 class="font-croissant text-6xl text-primary">Best Selfie Challenge</h1>
        <div class="">Upload your selfie in our wedding event. We have amazing prizes at the end of the programme.</div>
    </div>
    
    <div class="flex gap-4 mt-8 flex-col lg:flex-row xl:flex-row">
        <form @submit.prevent="uploadPhoto" class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start border border-gray-200 w-full p-12 cursor-pointer">
            <fieldset :disabled="!!uploadTask">
                <button type="button" class="w-full" @click="open({ accept: 'image/*', multiple: false })">
                    <div class="text-center text-4xl text-primary">Upload Image</div>
                    <div class="mx-auto">
                        <div class="w-64 h-auto p-6 mx-auto">
                            <img src="/assets/svgs/undraw_upload_image_re_svxx.svg" alt="Upload Image"/>
                        </div>
                    </div>
                    <template v-if="files?.length === 1" >
                        <div class="font-sans"><b>Selected file:</b> {{ files[0].name }} <br /> (Click to select another)</div>
                    </template>
                    <template v-else>
                        
                        <div class="font-sans">Select one picture</div>
                    </template>
                </button>
                <template v-if="files?.length === 1" >
                    <button class="w-full text-center py-2 mt-4 bg-secondary text-white rounded-md font-sans disabled:bg-secondary-darker" :disabled="this.uploadProgress === 1">Upload {{ this.uploadProgress === 1 ? 'Complete!' : '(' + this.uploadProgress * 100 + ')' }}</button>
                    <div class="font-sans text-red">{{ this.uploadError }}</div>
                </template>
            </fieldset>
        </form>
    </div>
    <div class="flex flex-wrap gap-4 justify-center mt-8 lg:justify-between xl:justify-between">
        <img v-for="image in this.images" :key="image" :src="image" class="flex flex-wrap h-auto w-full lg:h-60 xl:h-60 lg:w-auto xl:w-auto border border-gray-300 shadow-lg" />
    </div>
    
</template>

<script>
import { db, collectionSelfie, selfieReference, } from '../firebase'
import { doc, setDoc, Timestamp } from 'firebase/firestore'
import { ref as storageRef, getStorage, listAll, getDownloadURL } from 'firebase/storage'
import { useFirebaseStorage, useStorageFile} from 'vuefire'
import { useFileDialog } from '@vueuse/core'

export default {
    data() {
        return {
            files: [],
            open: null,
            storage: null,
            uploadProgress: null,
            uploadError: null,
            uploadTask: null,
            images: []
        }
    },
    methods: {
        async uploadPhoto() {
            const data = this.files[0]
            console.log(data);
            const fileName = selfieReference + this.generateId(6, data.name);
            const fileReference = storageRef(this.storage, fileName)

            const {
                uploadProgress,
                uploadError,
                uploadTask,
                upload,
                } = useStorageFile(fileReference)

            this.uploadProgress = uploadProgress;
            this.uploadTask = uploadTask;
            this.uploadError = uploadError;

            if (data) {
                await upload(data)
            }

            await setDoc(doc(db, collectionSelfie, this.generateId(6, 'selfie').trim()), {
                fileurl: fileName,
                created: Timestamp.now()
            });

            await this.getAllImages();
        },
        generateId(length, name) {
            const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = ' ';
            const charactersLength = characters.length;
            for ( let i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            name = name.toLowerCase().trim().replaceAll(' ', '-').trim()
            return result + '-' + name;
        },
        async getAllImages() {
            const storage = getStorage();
            const listRef = storageRef(storage, 'selfies');

            const firstPage = await listAll(listRef);
            this.images = [];
            firstPage.items.forEach(selfie => {
                getDownloadURL(storageRef(storage, selfie.fullPath)).then((url) => {
                    this.images.push(url);
                })
            });
        }
    },
    mounted() {
        this.storage = useFirebaseStorage();
        const { files, open } = useFileDialog();
        this.files = files;
        this.open = open;

        this.getAllImages();
    },
    watch: {
        files: function(newFiles) {
            if (newFiles != null) {
                this.uploadPhoto();
            }
        }
    }
}
</script>