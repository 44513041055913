<template>
    <h1 class="text-xl">Total Visitors: {{ this.total }}</h1>
    <h1 class="text-lg mt-4">Total Slots: {{ this.totalSlots }}</h1>
    <h1 class="text-lg">Total Available Slots: {{ this.totalAvailable }}</h1>
    <div class="flex flex-col gap-y-6 mt-4">
        <div v-for="todo in this.todos" :key="todo.id" class="flex shadow-md p-4 flex-wrap rounded-2xl text-gray-50" :class="{ 'bg-red-500' : !todo.accept, 'bg-green-500' : todo.accept }">
            <div class="w-full lg:w-1/2 xl:w-1/2"><b>Name:</b> {{ todo.name }}</div>
            <div class="w-full lg:w-1/2 xl:w-1/2"><b>Count:</b> {{ todo.count }}</div>
            <div class="w-full lg:w-1/2 xl:w-1/2"><b>Accepted:</b> {{ (todo.accept) ? 'Accepts with pleasure' : 'Declines with regret' }}</div>
            <div class="w-full lg:w-1/2 xl:w-1/2"><b>Responded At:</b> {{ todo.created.toDate() }}</div>
            <div class="w-full"><b>Notes:</b> {{ todo.notes }}</div>
        </div>
    </div>
</template>

<script default>
import { useCollection } from 'vuefire'
import { query, orderBy, getAggregateFromServer, sum, where } from 'firebase/firestore'
import { rsvpRef } from '../firebase'

export default {
    data() {
        return {
            todos: [],
            total: 0,
            totalSlots: 150,
            totalAvailable: 0
        }
    },
    async created() {
        const qr = query(rsvpRef, orderBy('created', 'desc'))
        this.todos = useCollection(qr)

        const qrTotal = query(rsvpRef, where('accept', '==', true))

        const snapshot = await getAggregateFromServer(qrTotal, {
            total: sum('count')
        });

        this.total = snapshot.data().total
        this.totalAvailable = this.totalSlots - this.total
    },
    methods: {

    }
}

</script>

