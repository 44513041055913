<template>
    <div class="w-full flex flex-col justify-center content-center mt-14 h-52 max-w-6xl mx-auto px-6">
        <div class="font-croissant w-full text-center text-primary text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl">P & R</div>
        <div class="border-b w-full mx-auto border-gray-700">&nbsp;</div>
        <div class="font-croissant w-full text-center text-xl text-primary mt-6 tracking-widest">01.25.2024</div>
        <div class="font-croissant w-full text-center text-xs text-primary mt-2 tracking-widest">© {{ new Date().getFullYear() }} PR Solutions. All rights reserved.</div>
    </div>
</template>

<script>

export default {
  name: 'FooterPage'
}
</script>