<template>
  <div class="w-full bg-tertiary pt-10 pb-6 shadow-md">
    <div class="mx-auto w-full justify-center px-6 max-w-6xl text-primary">
      <h1 class="font-croissant w-full text-center text-5xl sm:text-5xl md:text-5xl lg:text-7xl xl:text-7xl ">Paul & Rachelle</h1>
      <div class="flex flex-wrap justify-between font-croissant mt-12 gap-y-4 gap-x-2">
        <router-link to="/" class="router-link">Home</router-link>
        <router-link to="/venue" class="router-link">Venue</router-link>
        <router-link to="/programme" class="router-link">Programme</router-link>
        <router-link to="/our-story" class="router-link">Our Story</router-link>
        <router-link to="/photos" class="router-link">Photos</router-link>
        <router-link to="/RSVP" class="router-link bg-primary">RSVP</router-link>
      </div>
    </div>
  </div>
  
  <div class="w-full max-w-6xl mx-auto px-6 py-6">
    <router-view></router-view>
  </div>
  <FooterPage />
</template>

<script>
import FooterPage from './components/FooterPage.vue'

export default {
  name: 'App',
  components: {
    FooterPage
  }
}
</script>