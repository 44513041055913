<template>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start border border-gray-200">
        <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start">
            <div class="flex flex-wrap flex-col justify-center p-4 xl:py-12 lg:py-12">
                <h3 class="text-4xl w-full text-center text-primary">Ikaw lang ang aking itatangi at makailang ulit na ihaharap sa pulang bandila</h3>
                <div class="flex flex-wrap mt-6 justify-center gap-2">
                    <img src="/assets/prenup/tagalog/photo1.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo2.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo3.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo4.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo5.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo6.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo7.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo8.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo9.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                    <img src="/assets/prenup/tagalog/photo10.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Tagalog Custome"/>
                </div>
            </div>
        </div>
    </div>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start mt-6 border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center p-4 xl:py-12 lg:py-12">
            <h3 class="text-4xl w-full text-center text-primary">I'm wearing the smile you gave to me</h3>
            <div class="flex flex-wrap mt-6 justify-center gap-2">
                <img src="/assets/prenup/formal/photo1.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo2.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo3.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo4.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo5.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo6.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo7.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo10.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo8.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
                <img src="/assets/prenup/formal/photo9.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Formal Custome"/>
            </div>
        </div>
    </div>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start mt-6 border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center p-4 xl:py-12 lg:py-12">
            <h3 class="text-4xl w-full text-center text-primary">Life is a long winding ride. Better to have the right one by your side</h3>
            <div class="flex flex-wrap mt-6 justify-center gap-2">
                <img src="/assets/prenup/rider/photo1.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo2.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo3.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo4.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo5.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo6.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo7.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo10.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo8.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
                <img src="/assets/prenup/rider/photo9.jpg" class="w-full h-auto lg:w-auto lg:h-56 xl:w-auto xl:h-56 shadow-lg" alt="Rider Custome"/>
            </div>
        </div>
    </div>
</template>