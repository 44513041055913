<template>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
            <div class="w-64 h-auto p-6 mx-auto">
                <img src="/assets/svgs/undraw_to_do_list_re_9nt7.svg" alt="Wedding Ceremony"/>
            </div>
            <div class="flex flex-col p-6 w-full">
                <div class="w-auto my-auto flex justify-center lg:justify-start xl:justify-start" v-if="!this.submitted">
                    <h3 class="text-4xl text-primary">R S V P</h3>
                </div>
                <div v-if="this.submitted">
                    <div class="bg-green-600 p-6 text-gray-50 rounded-lg">
                        <span v-if="!this.getAcceptedValue()">We are sad to know that you have decline your attendance to our event. But we really appreciate it! Have a good day to you!</span>
                        <span v-if="this.getAcceptedValue()">We are happy that you have confirmed your attendance to our event. We really appreciate it! We look forward to seeing you soon!</span> 
                    </div>
                </div>
                <form class="w-full my-auto font-sans" v-if="!this.submitted">
                    <div class="text-lg mt-4">We have reserved a seat(s) for you. The favor of your response is requested on or before <b>January 15, 2024</b></div>
                    <div class="flex flex-wrap mt-4">
                        <label class="my-auto w-full lg:w-1/3 xl:w-1/3">Name: </label>
                        <div class="w-full lg:w-2/3 xl:w-2/3">
                            <input type="text" v-model="this.name" class="border border-secondary rounded-lg p-2 w-full" v-bind:class="{ 'border-red-800' : this.errors.name.length > 0}" />
                            <div v-for="error in this.errors.name" v-bind:key="error" class="text-sm text-red-800 w-full">{{ error }}</div>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-4">
                        <label class="my-auto w-full lg:w-1/3 xl:w-1/3">Number of Guests attending: </label>
                        <input type="number" v-model.number="this.count" class="border border-secondary rounded-lg p-2 w-full lg:w-2/3 xl:w-2/3" />
                    </div>
                    <div class="flex items-center flex-row-reverse lg:flex-row xl:flex-row mt-2 justify-center lg:justify-start xl:justify-start">
                        <label for="bordered-checkbox-1" class="w-40 py-2 lg:w-1/3 xl:w-1/3">Accepts with pleasure</label>
                        <input id="bordered-checkbox-1" type="radio" v-model="this.accept" value="true" name="bordered-checkbox" class="w-6 h-6 mr-4 text-primary text-start bg-tertiary border-tertiary rounded">
                    </div>
                    <div class="flex items-center flex-row-reverse lg:flex-row xl:flex-row justify-center lg:justify-start xl:justify-start">
                        <label for="bordered-checkbox-2" class="w-40 py-2 lg:w-1/3 xl:w-1/3">Declines with regret</label>
                        <input id="bordered-checkbox-2" type="radio" v-model="this.accept" value="false" name="bordered-checkbox" class="w-6 h-6 mr-4 text-primary text-start bg-tertiary border-tertiary rounded">
                    </div>
                    <div class="flex flex-wrap mt-4">
                        <label class="my-auto w-full lg:w-1/3 xl:w-1/3">Notes: </label>
                        <textarea v-model="this.notes" class="border border-secondary rounded-lg p-2 w-full lg:w-2/3 xl:w-2/3" rows="4" ></textarea>
                    </div>
                    <div class="mt-4 flex justify-end">
                        <button type="button" @click="submitForm" class="w-full lg:w-2/3 xl:w-2/3 bg-secondary py-2 rounded-lg text-white" :disabled="submitting">Submit</button>
                    </div> 
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { db, collectionName } from '../firebase'
import { doc, setDoc, Timestamp } from 'firebase/firestore'

export default {
    data() {
        return {
            name: '',
            count: 1,
            accept: true,
            notes: '',
            errors: {
                name: [],
            },
            submitting: false,
            submitted: false
        }
    },
    methods: {
        async submitForm() {
            if (this.name === '' || this.name == undefined) {
                this.errors.name.push("The name field is required.")
                return
            }

            this.submitting = true
            
            await setDoc(doc(db, collectionName, this.generateId(6, this.name)), {
                name: this.name,
                count: this.count,
                accept: this.getAcceptedValue(),
                notes: this.notes,
                created: Timestamp.now()
            });

            this.submitted = true
        },
        getAcceptedValue() {
            let accepted = false;

            if (this.accept === true || this.accept === 'true') {
                accepted = true;
            }
            return accepted;

        },
        generateId(length, name) {
            const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = ' ';
            const charactersLength = characters.length;
            for ( let i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            name = name.toLowerCase().trim().replaceAll(' ', '-').trim()
            return result + '-' + name;
        }
    }
}
</script>