<template>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start border border-gray-200 p-6">
        <div>
            <div class="w-auto my-auto flex flex-row justify-center">
                <h1 class="text-5xl text-primary">Calibuso-Peñaflor</h1>
            </div>
            <div class="w-auto my-auto flex flex-row justify-center">
                <h1 class="text-lg text-primary font-sans">Wedding Entourage</h1>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Officiating Priest</h3>
            </div>
            <div class="font-sans text-center">
                Rev.Fr. Herald Mart C. Arenal
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Primary Sponsors</h3>
            </div>
            <div class="flex font-sans flex-col lg:flex-row xl:flex-row">
                <div class="flex flex-col w-full lg:text-end xl:text-end mr-4">
                    <div class="hidden lg:block xl:block">&nbsp;</div>
                    <div>Rev.Fr. Frederick Tacderas</div>
                    <div>Mr. Joselito A. Pascual</div>
                    <div>Mr. Randy B. Calibara</div>
                    <div>Mr. Ronaldo M. Calibuso</div>
                    <div>Mr. Tomasito Saliba</div>
                    <div>Mr. Domingo M. Calibuso</div>
                    <div>Mr. Elmer M. Calibuso</div>
                    <div>Mr. Tolentino A. Dy Sr.</div>
                    <div>Mr. Reynaldo V. Glean</div>
                    <div>Mr. Alejandrino B. Cayme</div>
                    <div>Mr. Pablo Cayme</div>
                    <div>Mr. Sinforiano D. Transfiguracion</div>
                    <div>Mr. Alexander Consebido Sr.</div>
                    <div>Mr. Alberto Alba</div>
                </div>
                <div class="flex flex-col w-full lg:text-start xl:text-start ml-0 lg:ml-4 xl:ml-4 mt-6 lg:mt-0 xl:mt-0">
                    <div>Sr.Ma. Margherita E. Mondano, OSBS</div>
                    <div>Sr.Ma. Emilia V. Loria, OSBS </div>
                    <div>Mrs. Daisy T. Romero</div>
                    <div>Mrs. Ma. Victoria B. Cervantes</div>
                    <div>Mrs. Melanie B. Pelayo</div>
                    <div>Mrs. Corazon Saliba</div>
                    <div>Ms. Teodora B. Cayme</div>
                    <div>Mrs. Liza L. Cayco</div>
                    <div>Mrs. Annabelle E. Dy</div>
                    <div>Mrs. Mary R. Glean</div>
                    <div>Mrs. Eliza C. Cayme</div>
                    <div>Mrs. Emma Q. Cayme</div>
                    <div>Mrs. Placida Worell</div>
                    <div>Mrs. Cherry Consebido</div>
                    <div>Mrs. Mary Joy Alba</div>
                    <div>Ms. Minda Briones</div>
                    <div>Mrs. Josefina C. Fernandez</div>
                </div>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Secondary Sponsors</h3>
            </div>
            <div class="flex font-sans flex-col lg:flex-row xl:flex-row mt-4">
                <div class="flex flex-col w-full lg:text-end xl:text-end mr-4">
                    <div class="font-croissant text-3xl text-primary">Bestman</div>
                    <div>Mr. Rey Joshua R. Glean</div>
                    <div class="font-croissant text-3xl mt-4 text-primary">Groomsmen</div>
                    <div>Mr. Aldrin Jake C.Cayme</div>
                    <div>Mr. John Andrew C. Arenal</div>
                    <div>Mr. Mark Jayson Reyes</div>
                    <div>Mr. Terence Tiongson</div>
                    <div>Mr. Charlemaigne Villadiego</div>
                    <div class="font-croissant text-3xl mt-4 text-primary">Junior Groomsmen</div>
                    <div>Mr. Gyssen Brylle Acob</div>
                    <div>Mr. Alston Myles Cayme</div>
                    <div>Mr. Paulo Miguel C. Cayme</div>
                </div>
                <div class="flex flex-col w-full lg:text-start xl:text-start ml-0 lg:ml-4 xl:ml-4 mt-6 lg:mt-0 xl:mt-0">
                    <div class="font-croissant text-3xl text-primary">Maid of Honor</div>
                    <div>Ms. Ma. Rosarae C. Arenal</div>
                    <div class="font-croissant text-3xl mt-4 text-primary">Braidsmaids</div>
                    <div>Ms. Jopha C. Calibuso</div>
                    <div>Ms. Yumi P. Kitahara</div>
                    <div>Ms. Maria Gabriela M. Peñaflor</div>
                    <div>Ms. Princess Margarette Cayme</div>
                    <div>Ms. Ylena Mae N. Peñaflor</div>
                    <div class="font-croissant text-3xl mt-4 text-primary">Junior Braidsmaids</div>
                    <div>Ms. Zhaira Mae N. Peñaflor</div>
                    <div>Ms. Sidney Harriete O. Peñaflor</div>
                    <div>Ms. Aviela C. See</div>
                    <div>Ms. Aliah A. Caliste</div>
                </div>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Candle</h3>
            </div>
            <div class="flex font-sans flex-col lg:flex-row xl:flex-row">
                <div class="flex flex-col w-full lg:text-end xl:text-end mr-4">
                    <div>Mr. Aldrin Jake C. Cayme</div>
                </div>
                <div class="flex flex-col w-full lg:text-start xl:text-start ml-0 lg:ml-4 xl:ml-4">
                    <div>Ms. Jopha C. Calibuso</div>
                </div>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Viel</h3>
            </div>
            <div class="flex font-sans flex-col lg:flex-row xl:flex-row">
                <div class="flex flex-col w-full lg:text-end xl:text-end mr-4">
                    <div>Mr. John Andrew C. Arenal</div>
                </div>
                <div class="flex flex-col w-full lg:text-start xl:text-start ml-0 lg:ml-4 xl:ml-4">
                    <div>Ms. Yumi P. Kitahara</div>
                </div>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Cord</h3>
            </div>
            <div class="flex font-sans flex-col lg:flex-row xl:flex-row">
                <div class="flex flex-col w-full lg:text-end xl:text-end mr-4">
                    <div>Mr. Terence Tiongson</div>
                </div>
                <div class="flex flex-col w-full lg:text-start xl:text-start ml-0 lg:ml-4 xl:ml-4">
                    <div>Ms. Zhaira Mae N. Peñaflor</div>
                </div>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Ring Bearer</h3>
            </div>
            <div class="flex font-sans justify-center">
                <div>Romniel O. Peñaflor</div>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Bible Bearer</h3>
            </div>
            <div class="flex font-sans justify-center">
                <div>Ezekiel Froilan Arenal</div>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Coin Bearer</h3>
            </div>
            <div class="flex font-sans justify-center">
                <div>Gavebrielle Angelo M. Cayme</div>
            </div>
        </div>
        <div class="flex flex-col text-gray-700 mt-8 w-full">
            <div class="w-auto my-auto flex justify-center">
                <h3 class="text-3xl text-primary">Flower Girls</h3>
            </div>
            <div class="flex flex-col font-sans text-center">
                <div>Jorinne Paula C. Abob</div>
                <div>Allysa Myrrine Cayme</div>
                <div>Ezra Ysabelle C. Calibuso</div>
                <div>Patrizia Michayla C. Cayme</div>
                <div>Julia Essie C. Acob</div>
                <div>Athena Marie O. Peñaflor</div>
                <div>Reese Ayanna O.Peñaflor</div>
            </div>
        </div>
    </div>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start mt-8 border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
            <div class="w-64 h-auto p-6 m-auto">
                <img src="/assets/svgs/undraw_gift_re_qr17.svg" alt="Gift"/>
            </div>
            <div class="flex flex-col text-gray-700 p-6 w-full">
                <div class="w-auto my-auto flex justify-center lg:justify-start xl:justify-start">
                    <h3 class="text-4xl text-primary">Gift Guide</h3>
                </div>
                <div class="mt-2 text-sm font-sans">
                    With all that we have, we've been truly blessed. Your presence and prayers are all that we request.
                    But if you desire to give nonetheless. <b>Monetary gift is one we suggest.</b>
                </div>
            </div>
        </div>
    </div>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start mt-8 border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center content-start lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
            <div class="w-64 h-auto p-6 m-auto">
                <img src="/assets/svgs/undraw_businessman_e7v0.svg" alt="Gentleman Formal Attire"/>
            </div>
            <div class="flex flex-col text-gray-700 p-6 w-full">
                <div class="w-auto my-auto flex justify-center lg:justify-start xl:justify-start">
                    <h3 class="text-4xl text-primary">Men's Dress Code</h3>
                </div>
                <div class="w-full my-auto">
                    <div class="flex flex-col lg:flex-row xl:flex-row mt-8">
                        <div class="w-full pr-0 lg:pr-10 xl:pr-10 font-sans">
                            <h5 class="text-lg underline">Primary & Secondary Sponsors (Formal)</h5>
                            <div class="mt-2 text-sm">We would like to see you in <b>black suit</b> with <b>black tie</b> (bow or neck)</div>
                        </div>
                        <div class="w-full">
                            <h5 class="text-lg underline font-sans">Color Palette</h5>
                            <div class="flex mt-2 space-x-2 justify-center lg:justify-start xl:justify-start">
                                <div class="rounded-full w-10 h-10 bg-black border shadow-xl"></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row xl:flex-row mt-8">
                        <div class="w-full pr-0 lg:pr-10 xl:pr-10 font-sans">
                            <h5 class="text-lg underline">Guests (Semi-Formal)</h5>
                            <div class="mt-2 text-sm">We would like to see you either of the following:</div>
                            <ul class="list-disc text-sm ml-6 max-w-sm">
                                <li><b>Black suit</b> with <b>black tie</b> (bow or neck)</li>
                                <li><b>Long sleeves</b> (matching the color pallete bellow) with <b>black tie</b> (bow or neck)</li>
                            </ul>
                        </div>
                        <div class="w-full">
                            <h5 class="text-lg underline font-sans">Color Palette</h5>
                            <div class="flex mt-2 space-x-2 justify-center lg:justify-start xl:justify-start">
                                <div class="rounded-full w-10 h-10 bg-[#f2f1ed] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#939b86] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#3d4933] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#6e260e] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#9b5203] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#8e765e] border shadow-xl"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start mt-8 border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
            <div class="w-64 h-auto p-6 m-auto">
                <img src="/assets/svgs/undraw_art_thinking_3g82.svg" alt="Woman Formal Attire"/>
            </div>
            <div class="flex flex-col text-gray-700 p-6 w-full">
                <div class="w-auto my-auto flex justify-center lg:justify-start xl:justify-start">
                    <h3 class="text-4xl text-primary">Women's Dress Code</h3>
                </div>
                <div class="w-full my-auto">
                    <div class="flex flex-col lg:flex-row xl:flex-row mt-8">
                        <div class="w-full pr-0 lg:pr-10 xl:pr-10 font-sans">
                            <h5 class="text-lg underline">Primary Sponsors (Formal)</h5>
                            <div class="mt-2 text-sm">We would like to see you in <b>long gown</b> or <b>cocktail dress</b> matching <b>beige</b> color pallete below:</div>
                        </div>
                        <div class="w-full">
                            <h5 class="text-lg underline font-sans">Color Palette</h5>
                            <div class="flex mt-2 space-x-2 justify-center lg:justify-start xl:justify-start">
                                <div class="rounded-full w-10 h-10 bg-[#F5F5DC] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#edc9af] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#c3b091] border shadow-xl"></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row xl:flex-row mt-8">
                        <div class="w-full pr-0 lg:pr-10 xl:pr-10 font-sans">
                            <h5 class="text-lg underline">Secondary Sponsors & Flower Girls (Formal - c/o Couple)</h5>
                            <div class="mt-2 text-sm">We would like to see you in <b>long gown</b> or <b>cocktail dress</b> matching <b>sage green</b> color:</div>
                        </div>
                        <div class="w-full">
                            <h5 class="text-lg underline font-sans">Color Palette</h5>
                            <div class="flex mt-2 space-x-2 justify-center lg:justify-start xl:justify-start">
                                <div class="rounded-full w-10 h-10 bg-[#939b86] border shadow-xl"></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row xl:flex-row mt-8">
                        <div class="w-full pr-0 lg:pr-10 xl:pr-10 font-sans">
                            <h5 class="text-lg underline">Guest (Semi-Formal)</h5>
                            <div class="mt-2 text-sm">We would like to see you in <b>long gown</b> or <b>cocktail dress</b> matching the color pallete bellow:</div>
                        </div>
                        <div class="w-full">
                            <h5 class="text-lg underline font-sans">Color Palette</h5>
                            <div class="flex mt-2 space-x-2 justify-center lg:justify-start xl:justify-start">
                                <div class="rounded-full w-10 h-10 bg-[#f2f1ed] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#939b86] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#3d4933] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#6e260e] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#9b5203] border shadow-xl"></div>
                                <div class="rounded-full w-10 h-10 bg-[#8e765e] border shadow-xl"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="font-croissant rounded-lg shadow-lg text-center lg:text-start xl:text-start mt-8 border border-gray-200">
        <div class="flex flex-wrap flex-col justify-center lg:flex-nowrap xl:flex-nowrap lg:flex-row xl:flex-row lg:justify-between xl:justify-between content-center">
            <div class="w-64 h-auto p-6 m-auto mt-8">
                <img src="/assets/svgs/undraw_to_do_list_re_9nt7.svg" alt="Program List"/>
            </div>
            <div class="flex flex-col text-gray-700 p-6 w-full">
                <div class="mt-2 font-sans">The ceremony will take place at Imus Cathedral and the Reception will be at Patio Luisa Event Place. We will prepare a snack for our guests after the ceremony.</div>
                <h3 class="flex w-full text-4xl mt-4 underline text-primary justify-center lg:justify-start xl:justify-start">Programme</h3>
                <div class="flex flex-col mt-2 lg:flex-row xl:flex-row font-sans justify-center lg:justify-start xl:justify-start max-w-sm mx-auto lg:mx-0 xl:mx-0">
                    <ul class="list-disc text-sm ml-6 text-start">
                        <li>Call to Attention</li>
                        <li>Entrance of the Entourage</li>
                        <li>Save the date Video</li>
                        <li>Grand Entrance of the Newly Wed</li>
                        <li>First Dance</li>
                        <li>Prosperity Dance (via sash)</li>
                        <li>Cake Cutting</li>
                        <li>Toast for the Couple</li>
                        <li>Prayer before Dinner</li>
                        <li>Dinner</li>
                        <li>Trivia Game</li>
                        <li>Messages from Principal Sponsors, Friends & Parents</li>
                    </ul>
                    <ul class="list-disc text-sm ml-6 text-start">
                        <li>Single Guys Game (Shot, Turn and Shoot Game) </li>
                        <li>Single Ladies Game (Oh Gulay Game) </li>
                        <li>Garter Retrieval </li>
                        <li>Mirror Kissing </li>
                        <li>Same Day Edit Video </li>
                        <li>Thank you Speech of the Couple </li>
                        <li>Awarding</li>
                        <li>Closing Remarks</li>
                        <li>After Party</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</template>